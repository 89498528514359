module.exports = {
  "/add-domain": {},
  "/business-unit/view-all/add-domain": {},
  "/business-unit/:businessUnitId/add-domain": {},
  "/": {
    "success": {
      "domains tables loaded": [
        "COS_DOMAINS_TABLES_LOADED",
        "EMAIL_SENDING_DOMAINS_LOADED"
      ],
      "domains zero state": [
        "DOMAINS_ZERO_STATE"
      ],
      "domains no access": [
        "DOMAINS_NO_ACCESS"
      ],
      "domains cms pql": [
        "DOMAINS_CMS_PQL"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/security-settings/:domainId": {
    "name": "Security settings",
    "success": {
      "domains tables loaded": [
        "SECURITY_SETTINGS_PANEL",
        "COS_DOMAINS_TABLES_LOADED",
        "EMAIL_SENDING_DOMAINS_LOADED"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/add-domain/:step/:domainType": {
    "name": "Add domain",
    "success": {
      "add secondary domain": [
        "ADD_SECONDARY_DOMAIN_WIZARD"
      ],
      "add primary domain": [
        "ADD_PRIMARY_DOMAIN_WIZARD"
      ],
      "add redirect domain": [
        "ADD_REDIRECT_DOMAIN_WIZARD"
      ],
      "add email domain": [
        "ADD_EMAIL_DOMAIN_WIZARD"
      ],
      "connect dedicated IP": [
        "CONNECT_DEDICATED_IP_WIZARD"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/add-domain/:step/:domainType/:domainOrTaskId": {
    "name": "Resume add domain by domainOrTaskId",
    "success": {
      "add secondary domain": [
        "ADD_SECONDARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "add primary domain": [
        "ADD_PRIMARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "add redirect domain": [
        "ADD_REDIRECT_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "add email domain": [
        "ADD_EMAIL_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "connect dedicated IP": [
        "CONNECT_DEDICATED_IP_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/add-domain/:step/:domainType/:taskId/:domainId": {
    "name": "Resume add domain by task ID",
    "success": {
      "add secondary domain": [
        "ADD_SECONDARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "add primary domain": [
        "ADD_PRIMARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "add redirect domain": [
        "ADD_REDIRECT_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "connect dedicated IP": [
        "CONNECT_DEDICATED_IP_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/business-unit/:businessUnitId": {
    "success": {
      "business unit domains tables loaded": [
        "COS_DOMAINS_TABLES_LOADED",
        "EMAIL_SENDING_DOMAINS_LOADED"
      ],
      "business unit domains zero state": [
        "DOMAINS_ZERO_STATE"
      ],
      "business unit domains no access": [
        "DOMAINS_NO_ACCESS"
      ],
      "business unit domains cms pql": [
        "DOMAINS_CMS_PQL"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/business-unit/:businessUnitId/security-settings/:domainId": {
    "name": "Business unit security settings",
    "success": {
      "business unit domains tables loaded": [
        "SECURITY_SETTINGS_PANEL",
        "COS_DOMAINS_TABLES_LOADED",
        "EMAIL_SENDING_DOMAINS_LOADED"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/ssl-verification/:domainId": {
    "name": "SSL verification",
    "success": {
      "default": [
        "SSL_VERIFICATION_WIZARD"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/business-unit/:businessUnitId/ssl-verification/:domainId": {
    "name": "Business Unit SSL verification",
    "success": {
      "default": [
        "SSL_VERIFICATION_WIZARD"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/business-unit/:businessUnitId/add-domain/:step/:domainType": {
    "name": "Business unit add domain",
    "success": {
      "business unit add secondary domain": [
        "ADD_SECONDARY_DOMAIN_WIZARD"
      ],
      "business unit add primary domain": [
        "ADD_PRIMARY_DOMAIN_WIZARD"
      ],
      "business unit add redirect domain": [
        "ADD_REDIRECT_DOMAIN_WIZARD"
      ],
      "business unit add email domain": [
        "ADD_EMAIL_DOMAIN_WIZARD"
      ],
      "business unit connect dedicated IP": [
        "CONNECT_DEDICATED_IP_WIZARD"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/business-unit/:businessUnitId/add-domain/:step/:domainType/:domainOrTaskId": {
    "name": "Business unit resume add domain by domainOrTaskId",
    "success": {
      "business unit add secondary domain": [
        "ADD_SECONDARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit add primary domain": [
        "ADD_PRIMARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit add redirect domain": [
        "ADD_REDIRECT_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit add email domain": [
        "ADD_EMAIL_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit connect dedicated IP": [
        "CONNECT_DEDICATED_IP_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/business-unit/:businessUnitId/add-domain/:step/:domainType/:taskId/:domainId": {
    "name": "Business unit resume add add domain by taskID",
    "success": {
      "business unit add secondary domain": [
        "ADD_SECONDARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit add primary domain": [
        "ADD_PRIMARY_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit add redirect domain": [
        "ADD_REDIRECT_DOMAIN_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ],
      "business unit connect dedicated IP": [
        "CONNECT_DEDICATED_IP_WIZARD",
        "UPDATE_CNAME_WIZARD_STEP"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/url-redirects/business-unit/:businessUnitId": {},
  "/url-redirects": {
    "name": "URL redirects",
    "success": {
      "url redirects loaded": [
        "URL_REDIRECTS_LOADED"
      ],
      "url redirects zero state": [
        "URL_REDIRECTS_ZERO_STATE"
      ],
      "url redirects no access": [
        "URL_REDIRECTS_NO_ACCESS"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  },
  "/url-redirects/edit/:id": {
    "name": "Edit URL redirect",
    "success": {
      "url redirects loaded": [
        "URL_REDIRECTS_LOADED"
      ],
      "url redirects zero state": [
        "URL_REDIRECTS_ZERO_STATE"
      ],
      "url redirects no access": [
        "URL_REDIRECTS_NO_ACCESS"
      ]
    },
    "error": [
      "LOADING_FAILED"
    ]
  }
};