import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["domainId", "taskIdOrEmailDomain", "step"];
import PortalIdParser from 'PortalIdParser';
import { Map as ImmutableMap, List } from 'immutable';
import DedicatedIPAPIv2 from '../../api/DedicatedIPv2';

// @ts-expect-error not migrated to TS
import { getUrlPathToAddWizardStep } from './urlUtils';
import { history } from '../createHistory';
import { AddDomainWizardSteps, DomainType } from '../../Constants';
import { DedicatedIPWizardAccountTypes, PublicAccountStages, SET_UP_COMPLETED_STAGES, DedicatedIPMarketingSetupTypes, DedicatedIPAccountTypes, DedicatedIPTransactionalSetupTypes, DedicatedIPBusinessUnitPrefix } from '../../enums/DedicatedIP';
const DNS_RECORD_TYPES_ORDER = {
  'A (Address)': 1,
  'MX (Mail Domain)': 2,
  'TXT (Text Record)': 3,
  'CNAME (Alias)': 4
};
const DEFAULT_DOMAIN = 'domain.com';
export const getDedicatedMarketingSubDomain = () => `${PortalIdParser.get()}m`;
export const getDedicatedTransactionalSubDomain = () => `${PortalIdParser.get()}t`;
export const getSmtpDomain = ({
  accountType,
  domainName
}) => accountType === DedicatedIPWizardAccountTypes.DEDICATED_TRANSACTIONAL ? `${getDedicatedTransactionalSubDomain()}.${domainName}` : `${getDedicatedMarketingSubDomain()}.${domainName}`;
export const getPlaceholderDedicatedIPDomainName = accountType => accountType === DedicatedIPWizardAccountTypes.DEDICATED_TRANSACTIONAL ? `${getDedicatedTransactionalSubDomain()}.${DEFAULT_DOMAIN}` : `${getDedicatedMarketingSubDomain()}.${DEFAULT_DOMAIN}`;

/**
 * @deprecated - use DedicatedIPMarketingSetupTypes and DedicatedIPTransactionalSetupTypes instead
 */
export const parseDedicatedIPSetupAccountType = accountType => {
  switch (accountType) {
    case DedicatedIPWizardAccountTypes.DEDICATED_MARKETING:
      return {
        setupMarketing: true,
        setupTransactional: false
      };
    case DedicatedIPWizardAccountTypes.DEDICATED_TRANSACTIONAL:
      return {
        setupMarketing: false,
        setupTransactional: true
      };
    case DedicatedIPWizardAccountTypes.BOTH:
      return {
        setupMarketing: true,
        setupTransactional: true
      };
    default:
      return {
        setupMarketing: false,
        setupTransactional: false
      };
  }
};
export const getUpdatedDedicatedIPSetupAccountType = (existingDedicatedIPSetupAccountType, updatedDedicatedIPSetupTypeValues) => {
  const existingDedicatedIPSetupTypeValues = parseDedicatedIPSetupAccountType(existingDedicatedIPSetupAccountType);
  const {
    setupMarketing,
    setupTransactional
  } = Object.assign({}, existingDedicatedIPSetupTypeValues, updatedDedicatedIPSetupTypeValues);
  if (setupMarketing && setupTransactional) {
    return DedicatedIPWizardAccountTypes.BOTH;
  }
  if (setupMarketing) {
    return DedicatedIPWizardAccountTypes.DEDICATED_MARKETING;
  }
  if (setupTransactional) {
    return DedicatedIPWizardAccountTypes.DEDICATED_TRANSACTIONAL;
  }
  return null;
};
function compareDNSRecords(a, b) {
  const typeA = a.suggestedDomain.recordTypeTitle;
  const typeB = b.suggestedDomain.recordTypeTitle;
  return DNS_RECORD_TYPES_ORDER[typeA] - DNS_RECORD_TYPES_ORDER[typeB];
}
export const concatAndSortDNSRecords = dnsRecordResponses => {
  const marketingRecords = dnsRecordResponses[0] ? dnsRecordResponses[0].dnsRecordsToSetup : [];
  const transactionalRecords = dnsRecordResponses[1] ? dnsRecordResponses[1].dnsRecordsToSetup : [];
  return marketingRecords.concat(transactionalRecords).sort(compareDNSRecords);
};
export const normalizeDedicatedIpDnsRecords = rawDnsRecords => {
  const dnsRecords = rawDnsRecords.map(record => {
    const suggestedDomain = record.suggestedDomain;
    const dnsRecordCheck = record.dnsRecordCheck;
    const type = suggestedDomain.recordTypeTitle;
    const host = suggestedDomain.hostTitle;
    const expectedValue = dnsRecordCheck.expectedRecordValue;
    const actualValue = dnsRecordCheck.recordValueFound;
    const isValid = dnsRecordCheck.valid;
    return ImmutableMap({
      type,
      host,
      expectedValue,
      actualValue,
      isValid
    });
  });
  return List([ImmutableMap({
    conflictingRecords: List(),
    records: List(dnsRecords)
  })]);
};
export const getDedicatedIPParams = params => {
  const {
      domainId,
      taskIdOrEmailDomain,
      step
    } = params,
    other = _objectWithoutPropertiesLoose(params, _excluded);
  return Object.assign({
    setupAccountTypeParam: taskIdOrEmailDomain,
    domainNameParam: domainId,
    stepNameParam: step
  }, other);
};
export const redirectToDedicatedIPHostingSetup = (match, dedicatedIPSetupAccountType, rootDomainName) => {
  history.push(`${getUrlPathToAddWizardStep(match, AddDomainWizardSteps.HOSTING_SETUP, DomainType.DEDICATED_IP)}/${dedicatedIPSetupAccountType}/${rootDomainName}`);
};
export const getIsStatusSetUp = status => !!status && SET_UP_COMPLETED_STAGES.includes(status.publicAccountStage);

// Get current publicAccountStages for usage tracking

export const getUsageTrackingPublicAccountStages = ({
  dedicatedIPSetupAccountType,
  domainName,
  dedicatedMarketingStatusMap,
  dedicatedTransactionalStatusMap
}) => {
  const response = {};
  if (dedicatedIPSetupAccountType in DedicatedIPMarketingSetupTypes) {
    const currentMarketingStatus = dedicatedMarketingStatusMap[getSmtpDomain({
      accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING,
      domainName
    })];
    if (currentMarketingStatus) {
      response.marketingStage = currentMarketingStatus.publicAccountStage;
    }
  }
  if (dedicatedIPSetupAccountType in DedicatedIPTransactionalSetupTypes) {
    const currentTransactionalStatus = dedicatedTransactionalStatusMap[getSmtpDomain({
      accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL,
      domainName
    })];
    if (currentTransactionalStatus) {
      response.transactionalStage = currentTransactionalStatus.publicAccountStage;
    }
  }
  return response;
};
export const getVerifyDnsRecordsApiClient = ({
  accountType,
  smtpDomain,
  statusMap
}) => {
  const status = statusMap[smtpDomain];
  switch (status.publicAccountStage) {
    case PublicAccountStages.DNS_MISCONFIGURATION:
      return DedicatedIPAPIv2.verifyDedicatedIPDNSRecords({
        accountType,
        smtpDomain
      });
    default:
      return DedicatedIPAPIv2.verifyDedicatedIPDNSRecords({
        accountType,
        smtpDomain
      });
  }
};
export const getDedicatedIPLinkedBusinessUnits = status => status.dynamicPortalRoutes.filter(route => route.additionalRoutingKey.startsWith(DedicatedIPBusinessUnitPrefix)).map(route => Number(route.additionalRoutingKey.split(':')[1]));
export const getIsDefaultForUnassignedBusinessUnits = status => status.dynamicPortalRoutes.some(route => route.default === true);
export const getIsReassigningBusinessUnits = ({
  selectedBuIds,
  smtpDomain,
  isDefault,
  businessUnitToSmtpDomainMap,
  smtpDomainToBusinessUnitsMap
}) => {
  const currentBuIds = smtpDomainToBusinessUnitsMap[smtpDomain];
  const isGivingRemovedBuToDefault = !isDefault && currentBuIds && currentBuIds.some(currentBuId => !selectedBuIds.includes(currentBuId));
  const isTakingBuFromOtherIP = selectedBuIds.some(buId => {
    const isBusinessUnitAssigned = (buId in businessUnitToSmtpDomainMap);
    const isTakingBuFromDefault = !isBusinessUnitAssigned && !isDefault;
    const isTakingBuFromOtherSmtpDomain = isBusinessUnitAssigned && businessUnitToSmtpDomainMap[buId] !== smtpDomain;
    return isTakingBuFromDefault || isTakingBuFromOtherSmtpDomain;
  });
  return isGivingRemovedBuToDefault || isTakingBuFromOtherIP;
};