import Immutable from 'immutable';
import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { PrimaryUsageTypes as PrimaryUsageTypesFromContentUtils, SecondaryUsageTypes as SecondaryUsageTypesFromContentUtils } from 'ContentUtils/constants/Domains';
import { TLS, STRICT_TRANSPORT_SECURITY, HTTPS, CONTENT_SECURITY_POLICY, CONTENT_SECURITY_POLICY_REPORT_ONLY } from './enums/domains/SecurityHeaders';
import { DEFAULT_DURATION } from './enums/domains/HstsPredefinedDurations';
import { EditDomainDropdownActionTypes } from './enums/domains/EditDomainDropdownActionTypes';
import SpfCheckResultStatuses from './enums/domains/SpfCheckResultStatuses';
import DmarcCheckResultStatuses from './enums/domains/DmarcCheckResultStatuses';
export const DEFAULT_CONTENT_SECURITY_POLICY_VALUES = {
  enableNonce: false,
  userInputValue: ''
};
export const DEFAULT_HSTS_VALUES = {
  maxAge: DEFAULT_DURATION.value,
  includeSubdomains: false,
  enablePreload: false
};
export const NonHttpHeaderSecuritySettings = Immutable.List([TLS, HTTPS]);
export const ComplexSecuritySettings = Immutable.List([STRICT_TRANSPORT_SECURITY, CONTENT_SECURITY_POLICY, CONTENT_SECURITY_POLICY_REPORT_ONLY]);
export const UsageTypesKey = 'usageTypes';

// Usage Type parent enum
// From cos-domain BE's com.hubspot.content.model.domains.domainUsageType
export const DomainUsageTypes = {
  WEBSITE: 'WEBSITE',
  BLOG: 'BLOG',
  LANDING: 'LANDING',
  EMAIL: 'EMAIL',
  EMAIL_TRACKING: 'EMAIL_TRACKING',
  KNOWLEDGE_BASE: 'KNOWLEDGE_BASE',
  CUSTOMER_PORTAL: 'CUSTOMER_PORTAL',
  PODCAST: 'PODCAST'
};
// Display consistent ordering of Usage Types
export const DomainUsageTypeDisplayOrder = [DomainUsageTypes.WEBSITE, DomainUsageTypes.BLOG, DomainUsageTypes.LANDING, DomainUsageTypes.EMAIL, DomainUsageTypes.EMAIL_TRACKING, DomainUsageTypes.KNOWLEDGE_BASE, DomainUsageTypes.CUSTOMER_PORTAL, DomainUsageTypes.PODCAST];
export const PrimaryUsageTypes = Object.assign({}, PrimaryUsageTypesFromContentUtils, {
  PRIMARY_FOR_PODCAST: 'PRIMARY_FOR_PODCAST'
});
export const SecondaryUsageTypes = Object.assign({}, SecondaryUsageTypesFromContentUtils, {
  USED_FOR_PODCAST: 'USED_FOR_PODCAST'
});
export const UsageTypes = Object.assign({}, PrimaryUsageTypes, SecondaryUsageTypes);
// Provided parent DomainUsageType, gets list of child Usage Types
export const DomainUsageTypeToUsageTypesMap = {
  [DomainUsageTypes.LANDING]: [UsageTypes.PRIMARY_FOR_LANDING, UsageTypes.USED_FOR_LANDING],
  [DomainUsageTypes.WEBSITE]: [UsageTypes.PRIMARY_FOR_WEBSITE, UsageTypes.USED_FOR_WEBSITE],
  [DomainUsageTypes.BLOG]: [UsageTypes.PRIMARY_FOR_BLOG, UsageTypes.USED_FOR_BLOG],
  [DomainUsageTypes.KNOWLEDGE_BASE]: [UsageTypes.PRIMARY_FOR_KNOWLEDGE_BASE, UsageTypes.USED_FOR_KNOWLEDGE_BASE],
  [DomainUsageTypes.EMAIL]: [UsageTypes.PRIMARY_FOR_EMAIL, UsageTypes.USED_FOR_EMAIL],
  [DomainUsageTypes.EMAIL_TRACKING]: [UsageTypes.PRIMARY_FOR_EMAIL_TRACKING, UsageTypes.USED_FOR_EMAIL_TRACKING],
  [DomainUsageTypes.CUSTOMER_PORTAL]: [UsageTypes.PRIMARY_FOR_CUSTOMER_PORTAL, UsageTypes.USED_FOR_CUSTOMER_PORTAL],
  [DomainUsageTypes.PODCAST]: [UsageTypes.PRIMARY_FOR_PODCAST, UsageTypes.USED_FOR_PODCAST]
};

// Provided child Usage Type, gets parent DomainUsageType
export const UsageTypeToDomainUsageTypesMap = {
  [UsageTypes.USED_FOR_LANDING]: DomainUsageTypes.LANDING,
  [UsageTypes.PRIMARY_FOR_LANDING]: DomainUsageTypes.LANDING,
  [UsageTypes.USED_FOR_WEBSITE]: DomainUsageTypes.WEBSITE,
  [UsageTypes.PRIMARY_FOR_WEBSITE]: DomainUsageTypes.WEBSITE,
  [UsageTypes.USED_FOR_BLOG]: DomainUsageTypes.BLOG,
  [UsageTypes.PRIMARY_FOR_BLOG]: DomainUsageTypes.BLOG,
  [UsageTypes.USED_FOR_KNOWLEDGE_BASE]: DomainUsageTypes.KNOWLEDGE_BASE,
  [UsageTypes.PRIMARY_FOR_KNOWLEDGE_BASE]: DomainUsageTypes.KNOWLEDGE_BASE,
  [UsageTypes.USED_FOR_EMAIL]: DomainUsageTypes.EMAIL,
  [UsageTypes.PRIMARY_FOR_EMAIL]: DomainUsageTypes.EMAIL,
  [UsageTypes.PRIMARY_FOR_CUSTOMER_PORTAL]: DomainUsageTypes.CUSTOMER_PORTAL,
  [UsageTypes.USED_FOR_CUSTOMER_PORTAL]: DomainUsageTypes.CUSTOMER_PORTAL,
  [UsageTypes.PRIMARY_FOR_EMAIL_TRACKING]: DomainUsageTypes.EMAIL_TRACKING,
  [UsageTypes.USED_FOR_EMAIL_TRACKING]: DomainUsageTypes.EMAIL_TRACKING,
  [UsageTypes.PRIMARY_FOR_PODCAST]: DomainUsageTypes.PODCAST,
  [UsageTypes.USED_FOR_PODCAST]: DomainUsageTypes.PODCAST
};

// Index Usage Types within DomainUsageTypeToUsageTypesMap
export const UsageTypeIndices = {
  PRIMARY_FOR: 0,
  USED_FOR: 1
};
export const UsageTrackingDomainUsageTypes = {
  [DomainUsageTypes.WEBSITE]: 'Website',
  [DomainUsageTypes.BLOG]: 'Blog',
  [DomainUsageTypes.LANDING]: 'Landing Pages',
  [DomainUsageTypes.EMAIL]: 'Email',
  [DomainUsageTypes.KNOWLEDGE_BASE]: 'Knowledge Base',
  [DomainUsageTypes.CUSTOMER_PORTAL]: 'Customer Portal',
  [DomainUsageTypes.EMAIL_TRACKING]: 'Email Tracking',
  [DomainUsageTypes.PODCAST]: 'Podcast'
};

// PageBuilder domains only support these Usage Types
export const PageBuilderDomainUsageTypes = [DomainUsageTypes.LANDING, DomainUsageTypes.WEBSITE, DomainUsageTypes.BLOG];
export const DomainType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  REDIRECT: 'REDIRECT',
  EMAIL: 'EMAIL',
  TRACKED: 'TRACKED',
  DEDICATED_IP: 'DEDICATED_IP',
  BRAND_DOMAINS: 'BRAND_DOMAINS',
  REPLACE: 'REPLACE',
  DOWNGRADED: 'DOWNGRADED'
};
export const UsageTrackingDomainTypes = {
  [DomainType.PRIMARY]: 'Primary',
  [DomainType.SECONDARY]: 'Secondary',
  [DomainType.REDIRECT]: 'Redirect',
  [DomainType.EMAIL]: 'Email',
  [DomainType.TRACKED]: 'Tracked',
  [DomainType.DEDICATED_IP]: 'Dedicated IP',
  [DomainType.BRAND_DOMAINS]: 'Brand Domains',
  [DomainType.REPLACE]: 'Replace',
  [DomainType.DOWNGRADED]: 'Downgraded'
};
export const ModalsOpenedFromEditDropdown = {
  REPLACE: 'REPLACE',
  ADD_CONTENT_TYPES: 'ADD_CONTENT_TYPES',
  MAKE_PRIMARY: 'MAKE_PRIMARY',
  MAKE_PRIMARY_ALSO_SECONDARY: 'MAKE_PRIMARY_ALSO_SECONDARY',
  REMOVE_CONTENT_TYPE: 'REMOVE_CONTENT_TYPE',
  REMOVE: 'REMOVE',
  ASSIGN_TEAMS: 'ASSIGN_TEAMS',
  REPLACE_BRAND: 'REPLACE_BRAND',
  SET_PUBLISHING_STATE: 'SET_PUBLISHING_STATE',
  REPLACE_REDIRECT_TO_DOMAIN: 'REPLACE_REDIRECT_TO_DOMAIN',
  MAKE_REDIRECT_SECONDARY: 'MAKE_REDIRECT_SECONDARY',
  SHOW_DNS_RECORDS: 'SHOW_DNS_RECORDS',
  REVERSE_PROXY_VERIFICATION_TOOL: 'REVERSE_PROXY_VERIFICATION_TOOL'
};
export const UrlRedirectsScopes = {
  READ_ACCESS: 'cms-url-redirects-read',
  WRITE_ACCESS: 'cms-url-redirects-write'
};
export const DomainManagerScopes = {
  CUSTOM_DOMAIN_WRITE: 'content-custom-domain-write',
  HS_SITES_WRITE: 'hs-sites-domain-write',
  MULTI_DOMAIN_HOSTING: 'multi-domain-hosting',
  EMAIL_DOMAINS: 'email-sending-domains-access',
  EMAIL_DOMAINS_WRITE: 'email-sending-domains-write',
  TEAMS_ASSIGNMENT: 'content-team-assignment',
  TEAMS_CONTENT_PARTIONING: 'teams-content-partitioning',
  CONTENT_SETTINGS_ACCESS: 'content-settings-access',
  SITEMAPS_READ: 'sitemaps-read',
  SECURITY_HEADERS_READ: 'content-security-headers-read',
  SECURITY_HEADERS_WRITE: 'content-security-headers-write',
  DOMAINS_SETTINGS_WRITE: 'domains-settings-write',
  CUSTOM_APEX_DOMAIN_REDIRECT_WRITE: 'custom-apex-domain-redirect-write',
  REVERSE_PROXY_DOMAIN_WRITE: 'reverse-proxy-domain-write',
  ADDITIONAL_BRAND_DOMAINS: 'additional-brand-domains',
  BUSINESS_UNITS_ACCESS: 'business-units-access',
  BUSINESS_UNITS_VIEW_ALL: 'business-units-view-all',
  DEDICATED_IP_CANDIDATE: 'dedicated-ip-candidate',
  DEDICATED_IP_MARKETING: 'dedicated-ip-marketing',
  DEDICATED_IP_TRANSACTIONAL: 'dedicated-ip-transactional',
  CMS_GROWTH_THEME_ENTERPRISE: 'growth-cms-theme-enterprise',
  PARTNER_MATCHING_APP_ACCESS: 'partner-matching-app-access',
  DNS_RECORDS_READ: 'dns-records-read',
  SMS_PRODUCT_ACCESS: 'sms-product-access'
};
export const HubSpotSupportScopes = {
  ZORSE_CHATFLOW_TARGETING_ENTERPRISE: 'zorse-chatflow-targeting-enterprise',
  ZORSE_CHATFLOW_TARGETING_PRO: 'zorse-chatflow-targeting-pro',
  ZORSE_CHATFLOW_TARGETING_STARTER: 'zorse-chatflow-targeting-starter'
};
export const ScopesToCheck = Object.values(DomainManagerScopes).concat(Object.values(UrlRedirectsScopes), Object.values(HubSpotSupportScopes));
export const DomainManagerGates = {
  APEX_DOMAIN_REDIRECT_INFO: 'cos_apex_domain_redirect',
  TRACKED_DOMAINS: 'CMS:DisplayTrackedDomains',
  SUSPEND_ADD_DOMAIN: 'suspend-add-domains',
  LEGACY_TLS_VERSION: 'domains-legacy-tls-version',
  ADDITIONAL_BRAND_DOMAINS_ENTERPRISE_ONLY: 'settings-ui-domains:additional-brand-domains-enterprise-only',
  ENFORCE_BUSINESS_UNITS_ACCESS_SCOPE: 'business-units:enforce-access-scope',
  CREATE_REDIRECT_LIMIT: 'create-redirect-limit',
  REPLACEMENT_REDIRECT_HOSTING_SETUP: 'settings-ui-domains:replacement-redirect-hosting-setup',
  UPDATE_MATCH_QUERY_STRING_JOB: 'url-mapping:update-match-query-string-job',
  MATCH_EMPTY_QUERY_STRING: 'url-mapping:match-empty-query-string',
  REVERSE_PROXY_SETUP: 'domains:ReverseProxySetup',
  AUTOMATIC_REDIRECT: 'domains:apexRedirectEnabled',
  SSL_PROVISIONING_TOOL: 'domains:SslProvisioning',
  SPF_AND_DMARC: 'domains:spfAndDmarc'
};
export const GatesToCheck = Object.values(DomainManagerGates);
export const DomainParts = {
  SUBDOMAIN: 'SUBDOMAIN',
  BRAND: 'BRAND',
  TLD: 'TLD'
};
export const DomainPartValidationNames = ['EMPTY', 'TOO_LONG', 'INVALID_CHARACTERS', 'INVALID_STARTING_ENDING_CHARACTERS', 'INVALID_TLD_STARTING_CHARACTERS'];
const DomainPartValidationStates = DomainPartValidationNames.reduce((acc, key) => {
  Object.values(DomainParts).forEach(part => {
    const validationState = `${part}_${key}`;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    acc[validationState] = [part];
  });
  return acc;
}, {});
export const ValidationState = Object.assign({
  INITIAL: 'INITIAL',
  VALID_APEX_DOMAIN: 'VALID_APEX_DOMAIN',
  VALID_FULL_URL: 'VALID_FULL_URL',
  INVALID_DOMAIN: 'INVALID_DOMAIN',
  CONFLICT_OTHER_PORTAL: 'CONFLICT_OTHER_PORTAL',
  CONFLICT_THIS_PORTAL: 'CONFLICT_THIS_PORTAL',
  CONFLICT_OTHER_SERVICE: 'CONFLICT_OTHER_SERVICE',
  BLANK: 'BLANK',
  FAILED_TO_PARSE: 'FAILED_TO_PARSE',
  NON_REGISTRABLE: 'NON_REGISTRABLE',
  FULL_URL_REQUIRED: 'FULL_URL_REQUIRED',
  REDIRECT_SUBDOMAIN_REQUIRED_UNGATED: 'REDIRECT_SUBDOMAIN_REQUIRED_UNGATED',
  APEX_DOMAIN_NOT_SUPPORTED: 'APEX_DOMAIN_NOT_SUPPORTED',
  EXAMPLE_DOMAIN: 'EXAMPLE_DOMAIN',
  INVALID_PUBLIC_SUFFIX: 'INVALID_PUBLIC_SUFFIX',
  DOMAIN_IS_PUBLIC_SUFFIX: 'DOMAIN_IS_PUBLIC_SUFFIX',
  INCOMPLETE: 'INCOMPLETE',
  WWW_UNAVAILABLE: 'WWW_UNAVAILABLE',
  EXCEEDED_EXTERNAL_DOMAIN_LIMIT: 'EXCEEDED_EXTERNAL_DOMAIN_LIMIT',
  MISSING_PERMISSION: 'MISSING_PERMISSION',
  DOMAIN_IS_AN_SMTP_DOMAIN: 'DOMAIN_IS_AN_SMTP_DOMAIN',
  EMAIL_SENDING_DOMAIN_OWNED_BY_HUBSPOT: 'EMAIL_SENDING_DOMAIN_OWNED_BY_HUBSPOT',
  EMAIL_SENDING_DOMAIN_NOT_UNDER_PUBLIC_SUFFIX: 'EMAIL_SENDING_DOMAIN_NOT_UNDER_PUBLIC_SUFFIX',
  EMAIL_SENDING_DOMAIN_WOULD_REQUIRE_INVALID_DKIM_RECORD: 'EMAIL_SENDING_DOMAIN_WOULD_REQUIRE_INVALID_DKIM_RECORD',
  EMAIL_SENDING_DOMAIN_EXISTING_CNAME: 'EMAIL_SENDING_DOMAIN_EXISTING_CNAME',
  NON_REGISTRABLE_BRAND: 'NON_REGISTRABLE_BRAND',
  INVALID_TOP_LEVEL_DOMAIN: 'INVALID_TOP_LEVEL_DOMAIN',
  INVALID_SUBDOMAIN: 'INVALID_SUBDOMAIN',
  INVALID_BRAND_DOMAIN: 'INVALID_BRAND_DOMAIN',
  CLAIMED_BY_SETUP_DOMAINS: 'CLAIMED_BY_SETUP_DOMAINS',
  UNKNOWN: 'UNKNOWN'
}, Object.keys(DomainPartValidationStates).reduce((acc, key) => Object.assign({}, acc, {
  key
}), {}));
export const DomainErrorMap = Object.assign({
  [ValidationState.CONFLICT_OTHER_PORTAL]: [DomainParts.BRAND],
  [ValidationState.CONFLICT_THIS_PORTAL]: [DomainParts.BRAND],
  [ValidationState.CONFLICT_OTHER_SERVICE]: [DomainParts.BRAND],
  [ValidationState.BLANK]: Object.values(DomainParts),
  [ValidationState.FAILED_TO_PARSE]: Object.values(DomainParts),
  [ValidationState.NON_REGISTRABLE]: [DomainParts.BRAND],
  [ValidationState.FULL_URL_REQUIRED]: [DomainParts.BRAND],
  [ValidationState.REDIRECT_SUBDOMAIN_REQUIRED_UNGATED]: [DomainParts.SUBDOMAIN],
  [ValidationState.APEX_DOMAIN_NOT_SUPPORTED]: [DomainParts.BRAND],
  [ValidationState.EXAMPLE_DOMAIN]: [DomainParts.BRAND],
  [ValidationState.INVALID_PUBLIC_SUFFIX]: [DomainParts.TLD],
  [ValidationState.DOMAIN_IS_PUBLIC_SUFFIX]: [DomainParts.TLD],
  [ValidationState.INCOMPLETE]: Object.values(DomainParts),
  [ValidationState.WWW_UNAVAILABLE]: [DomainParts.SUBDOMAIN],
  [ValidationState.EXCEEDED_EXTERNAL_DOMAIN_LIMIT]: [DomainParts.BRAND],
  [ValidationState.MISSING_PERMISSION]: [DomainParts.BRAND],
  [ValidationState.DOMAIN_IS_AN_SMTP_DOMAIN]: [DomainParts.BRAND],
  [ValidationState.EMAIL_SENDING_DOMAIN_NOT_UNDER_PUBLIC_SUFFIX]: [DomainParts.TLD],
  [ValidationState.EMAIL_SENDING_DOMAIN_WOULD_REQUIRE_INVALID_DKIM_RECORD]: [DomainParts.BRAND],
  [ValidationState.NON_REGISTRABLE_BRAND]: [DomainParts.BRAND],
  [ValidationState.INVALID_DOMAIN]: Object.values(DomainParts),
  [ValidationState.INVALID_TOP_LEVEL_DOMAIN]: [DomainParts.TLD],
  [ValidationState.INVALID_SUBDOMAIN]: [DomainParts.SUBDOMAIN],
  [ValidationState.INVALID_BRAND_DOMAIN]: [DomainParts.BRAND],
  [ValidationState.UNKNOWN]: Object.values(DomainParts)
}, DomainPartValidationStates);
export const IndicatorTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DISABLED: 'default'
};
export const DomainWizards = {
  REPLACE_BRAND_DOMAIN_WIZARD: 'REPLACE_BRAND_DOMAIN_WIZARD'
};
export const AddDomainWizardSteps = {
  CONTENT_TYPE: 'CONTENT_TYPE',
  SELECT_DOMAIN: 'SELECT_DOMAIN',
  VERIFY_URLS: 'VERIFY_URLS',
  REVIEW_URLS: 'REVIEW_URLS',
  VERIFY_REDIRECT: 'VERIFY_REDIRECT',
  VERIFY_EMAIL_DOMAIN: 'VERIFY_EMAIL_DOMAIN',
  HOSTING_SETUP: 'HOSTING_SETUP',
  REDIRECT: 'REDIRECT',
  SELECT_BRAND: 'SELECT_BRAND',
  PICK_REPLACEMENTS: 'PICK_REPLACEMENTS',
  REVIEW_REPLACEMENTS: 'REVIEW_REPLACEMENTS'
};
export const AddPrimaryDomainWizardStepOrder = {
  [AddDomainWizardSteps.SELECT_DOMAIN]: 0,
  [AddDomainWizardSteps.CONTENT_TYPE]: 1,
  [AddDomainWizardSteps.VERIFY_URLS]: 2,
  [AddDomainWizardSteps.REVIEW_URLS]: 3,
  [AddDomainWizardSteps.HOSTING_SETUP]: 4
};
export const ScopeControlledAddPrimaryDomainWizardStepOrder = {
  [AddDomainWizardSteps.SELECT_DOMAIN]: 0,
  [AddDomainWizardSteps.VERIFY_URLS]: 1,
  [AddDomainWizardSteps.REVIEW_URLS]: 2,
  [AddDomainWizardSteps.HOSTING_SETUP]: 3
};
export const AddSecondaryDomainWizardStepOrder = {
  [AddDomainWizardSteps.SELECT_DOMAIN]: 0,
  [AddDomainWizardSteps.CONTENT_TYPE]: 1,
  [AddDomainWizardSteps.VERIFY_URLS]: 2,
  [AddDomainWizardSteps.REVIEW_URLS]: 3,
  [AddDomainWizardSteps.HOSTING_SETUP]: 4
};
export const AddRedirectDomainWizardStepOrder = {
  [AddDomainWizardSteps.SELECT_DOMAIN]: 0,
  [AddDomainWizardSteps.REDIRECT]: 1,
  [AddDomainWizardSteps.VERIFY_REDIRECT]: 2,
  [AddDomainWizardSteps.HOSTING_SETUP]: 3
};
export const AddEmailDomainWizardStepOrder = {
  [AddDomainWizardSteps.SELECT_DOMAIN]: 0,
  [AddDomainWizardSteps.VERIFY_EMAIL_DOMAIN]: 1,
  [AddDomainWizardSteps.HOSTING_SETUP]: 2
};
export const ReplaceBrandDomainWizardStepOrder = {
  [AddDomainWizardSteps.SELECT_BRAND]: 0,
  [AddDomainWizardSteps.PICK_REPLACEMENTS]: 1,
  [AddDomainWizardSteps.REVIEW_REPLACEMENTS]: 2,
  [AddDomainWizardSteps.HOSTING_SETUP]: 3
};
export const UrlPathParams = {
  ADD_DOMAIN: 'add-domain',
  SECURITY_SETTINGS: 'security-settings',
  SSL_VERIFICATION: 'ssl-verification',
  [AddDomainWizardSteps.CONTENT_TYPE]: AddDomainWizardSteps.CONTENT_TYPE,
  [AddDomainWizardSteps.SELECT_DOMAIN]: AddDomainWizardSteps.SELECT_DOMAIN,
  [AddDomainWizardSteps.REDIRECT]: AddDomainWizardSteps.REDIRECT,
  [AddDomainWizardSteps.VERIFY_URLS]: AddDomainWizardSteps.VERIFY_URLS,
  [AddDomainWizardSteps.VERIFY_EMAIL_DOMAIN]: AddDomainWizardSteps.VERIFY_EMAIL_DOMAIN,
  [AddDomainWizardSteps.VERIFY_REDIRECT]: AddDomainWizardSteps.VERIFY_REDIRECT,
  [AddDomainWizardSteps.REVIEW_URLS]: AddDomainWizardSteps.REVIEW_URLS,
  [AddDomainWizardSteps.HOSTING_SETUP]: AddDomainWizardSteps.HOSTING_SETUP,
  [AddDomainWizardSteps.SELECT_BRAND]: AddDomainWizardSteps.SELECT_BRAND,
  [AddDomainWizardSteps.PICK_REPLACEMENTS]: AddDomainWizardSteps.PICK_REPLACEMENTS,
  [AddDomainWizardSteps.REVIEW_REPLACEMENTS]: AddDomainWizardSteps.REVIEW_REPLACEMENTS
};
export const DomainWizardQueryParams = {
  REDIRECT_URL: 'redirectUrl'
};
export const AddDomainWizardFinalStepScreens = {
  SYNCHRONOUS_DOMAIN_CONNECT: 'SYNCHRONOUS_DOMAIN_CONNECT',
  ASYNCHRONOUS_DOMAIN_CONNECT: 'ASYNCHRONOUS_DOMAIN_CONNECT',
  HOSTING_STEPS_V2: 'HOSTING_STEPS_V2',
  SHORT_LOADING: 'SHORT_LOADING',
  LONG_LOADING: 'LONG_LOADING',
  FOUND_ERRORS: 'FOUND_ERRORS',
  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE'
};
export const WizardDoneLabelKeys = {
  DONE: 'done',
  VERIFY: 'verify',
  CHECK_AGAIN: 'checkAgain',
  NEXT: 'next'
};
const seleniumTestingKey = 'hs-selenium-testing';
const getIsSeleniumTesting = () => sessionStorage.getItem(seleniumTestingKey);
export const DNS_UPDATING_TIME_PERIOD = getIsSeleniumTesting() ? 20 * 1000 // 20 seconds
: 60 * 60 * 1000; // 60 Minutes

export const DomainConnectStep = {
  connect: 0,
  authorized: 1,
  domainVerified: 2
};
export const UpdateCnameStep = {
  contentReady: 'contentReady',
  login: 'login',
  goToDns: 'goToDns',
  updateDns: 'updateDns',
  verifyDomain: 'verifyDomain'
};
export const DomainSetupTaskCategories = {
  PRIMARY_CREATE: 'PRIMARY_CREATE',
  PRIMARY_REPLACE: 'PRIMARY_REPLACE',
  SECONDARY: 'SECONDARY',
  REDIRECT: 'REDIRECT',
  BRAND_REPLACE: 'BRAND_REPLACE',
  REVERSE_PROXY_SETUP: 'REVERSE_PROXY_SETUP'
};
export const DnsProviders = {
  GODADDY: 'GODADDY',
  NAMECHEAP: 'NAMECHEAP',
  ENOM: 'ENOM',
  REGISTER_DOT_COM: 'REGISTER_DOT_COM',
  ONE_AND_ONE: 'ONE_AND_ONE',
  BLUEHOST: 'BLUEHOST',
  NETWORK_SOLUTIONS: 'NETWORK_SOLUTIONS',
  CLOUDFLARE: 'CLOUDFLARE',
  GOOGLE: 'GOOGLE'
};
export const ContextualDnsErrors = {
  NONE: 'NONE',
  TYPO: 'TYPO',
  MISSING_RECORD: 'MISSING_RECORD',
  CONFLICTING_RECORD: 'CONFLICTING_RECORD'
};
export const DnsSetupGuideLink = 'https://knowledge.hubspot.com/domains-and-urls/update-your-dns-records';
export const TroubleshootingDnsErrorsLink = 'https://knowledge.hubspot.com/email/troubleshoot-your-email-sending-domain';
export const RequestStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
};
// These come from the DomainConnectChangeType.java enum in the BE
export const DomainConnectChangeTypes = {
  APEX_DOMAIN: 'APEX_DOMAIN',
  SUBDOMAIN: 'SUBDOMAIN',
  OWNERSHIP_VERIFICATION: 'OWNERSHIP_VERIFICATION',
  PREPROVISIONING: 'PREPROVISIONING',
  DKIM: 'DKIM'
};
export const ListingState = {
  LOADING: 'LOADING',
  EMPTY: 'EMPTY',
  RETRIEVED: 'RETRIEVED',
  ERROR: 'ERROR'
};
export const NewSystemDomainValidationCodes = {
  INVALID_DOMAIN: 'INVALID_DOMAIN',
  SUBDOMAIN_INVALID_CHARACTERS: 'SUBDOMAIN_INVALID_CHARACTERS',
  CONFLICT_THIS_PORTAL: 'CONFLICT_THIS_PORTAL',
  CONFLICT_OTHER_PORTAL: 'CONFLICT_OTHER_PORTAL',
  MULTI_LEVEL: 'MULTI_LEVEL',
  UNKNOWN: 'UNKNOWN'
};
const PORTAL_ID = PortalIdParser.get();
const ROOT_PATH = getFullUrl('app');
export const LANGUAGE_SETTINGS_URL = `${ROOT_PATH}/settings/${PORTAL_ID}/marketing/domains/languageSettings`;
export const TRACKING_SETTINGS_URL = `${ROOT_PATH}/settings/${PORTAL_ID}/analytics-and-tracking/domains`;
export const SITEMAP_URL = `${ROOT_PATH}/settings/${PORTAL_ID}/marketing/domains/sitemaps`;
export const CUSTOM_SSL_URL = `${ROOT_PATH}/custom-ssl/${PORTAL_ID}`;
export const PURCHASE_MORE_BRANDS_URL = `${ROOT_PATH}/l/browse/upgrade/brand-domain`;
export const UPGRATE_TO_MARKETING_ENTERPRISE_URL = `${ROOT_PATH}/l/browse/compare/marketing?product=enterprise`;
export const URL_REDIRECTS_PER_PAGE = 10;
export const BRAND_DOMAINS_KNOWLEDGE_ARTICLE_LINK = 'https://knowledge.hubspot.com/domains-and-urls/connect-additional-brand-domains';
export const SUBSCRIPTION_LIMITS_FOR_DOMAINS_LINK = 'https://knowledge.hubspot.com/domains-and-urls/understand-subscription-limits-for-domains';
export const CONNECT_DOMAIN_KNOWLEDGE_ARTICLE_LINK = 'https://knowledge.hubspot.com/domains-and-urls/connect-a-domain-to-hubspot';
export const URL_MAX_LENGTH = 250;
export const NOTE_MAX_LENGTH = 140;
export const CONTENT_SECURITY_POLICY_MAX_CHAR_COUNT = 4000;
export const ALL_USERS_FILTER_VALUE = -1;
export const VIEW_ALL_BUSINESS_UNITS_VALUE = 'view-all';
export const DEFAULT_EMPTY_SUBDOMAIN = '';
export const DEFAULT_DOMAINS_SUFFIX = 'com';
export const DEFAULT_DOMAINS_PRIMARY_LANGUAGE = 'en';
export const DEFAULT_FETCH_PRIMARY_LANGUAGES_REQUEST_STATUS = RequestStatus.UNINITIALIZED;
export const DEFAULT_SUBDOMAIN_VALIDATION_STATE = ValidationState.VALID_FULL_URL;
export const DEFAULT_EMPTY_SUBDOMAIN_VALIDATION_ERROR_LIST = [];
export const FLEXBILE_URL_REDIRECT_IDENTIFIERS = ['*', ':'];
export const URL_REDIRECT_LIMIT = 2500;
export const PreferredDefaultAutomaticRedirectSubdomains = ['', 'www'];
export const AutomaticRedirectApexTracking = '-APEX-';
export const EditDomainDropdownActionTypesToGroups = {
  [EditDomainDropdownActionTypes.SHOW_DNS_RECORDS]: 1,
  [EditDomainDropdownActionTypes.VERIFY_SSL_CERTIFICATE]: 1,
  [EditDomainDropdownActionTypes.VALIDATE_REVERSE_PROXY_DOMAIN]: 1,
  [EditDomainDropdownActionTypes.SET_PUBLISHING_STATE]: 2,
  [EditDomainDropdownActionTypes.REPLACE_REDIRECT_TO_DOMAIN]: 2,
  [EditDomainDropdownActionTypes.ADD_CONTENT_TYPES]: 2,
  [EditDomainDropdownActionTypes.REPLACE]: 2,
  [EditDomainDropdownActionTypes.REPLACE_BRAND]: 2,
  [EditDomainDropdownActionTypes.MAKE_PRIMARY_ALSO_SECONDARY]: 2,
  [EditDomainDropdownActionTypes.MAKE_REDIRECT_SECONDARY]: 2,
  [EditDomainDropdownActionTypes.EDIT_DOMAIN_SECURITY]: 2,
  [EditDomainDropdownActionTypes.MAKE_PRIMARY]: 2,
  [EditDomainDropdownActionTypes.ASSIGN_TEAMS]: 2,
  [EditDomainDropdownActionTypes.REMOVE_CONTENT_TYPE]: 3,
  [EditDomainDropdownActionTypes.REMOVE]: 3
};
export const SpfGuidanceKbUrl = 'https://knowledge.hubspot.com/marketing-email/manage-email-authentication-in-hubspot#combining-multiple-spf-records';
export const SpfRecordsStatusCheckDefaultValue = SpfCheckResultStatuses.UNKNOWN_ERROR;
export const DmarcStatusCheckDefaultValue = DmarcCheckResultStatuses.ERROR_UNKNOWN;
export const IsDkimConnectedDefaultValue = false;